@import '../../../styles/variables.scss'; 

.toaster {
  @include transition;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  width: 200px;
  margin: 20px;
  padding: 10px;
  z-index: 3000;
  transform: translateX(-240px);
  box-shadow: $input-shadow;
  background-color: $success;
  color: $white;
  p {
    margin: 0px;
    min-height: 38px;
    text-align: left;

  }
  &.success {
    background-color: $success;
    color: $white;
  }
  &.error {
    background-color: $error;
    color: $white;
  }
  &.isActive {
    transform: translateX(0px);
  }
}