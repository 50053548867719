@import '../../styles/variables.scss';

.keys-list {
  @include transition;
  margin: 10px 0px 30px 0px;;
  label {
    color: $grey;
    font-size: 14px;
  }
  .edit-key {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background-color: $light-grey;
    color: $grey;
    .icons-list {
      display: flex;
      flex-wrap: wrap;
      max-height: 200px;
      overflow: auto;
      .icon {
        @include transition;
        margin: 5px;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          border: 1px solid $grey;
        }
      }
    }
  }
  .key-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #E0E3EF;
    border-radius: 10px;
    padding: 10px;
    background-color: $white;
    color: $grey;
    margin-bottom: 10px;
    h3 {
      font-size: 22px;
      margin: 0px;
      color: #000000;
    }
    a {
      color: $grey; 
      &:hover {
        color: $dark-grey; 
      }
    }
    .key-infos {
      max-width: 80%;
      svg {
        margin-right: 15px;
      }
    }
    .key-title {
      font-size: 18px;
      margin: 0px;
      color: $dark;
      span {
        display: inline-block;
        margin-right: 10px;
      }
    }
    p {
      margin: 0px;
      color: $grey;
    }
  }
  
}