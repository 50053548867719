@import './variables.scss';

$keran: #8685BB;
$sce : #EB690B;
$ys : #229FBD;
$creo : #3A6DB0;
$up: #EB690B;
$huit : #0BB0AB;
$naomis: #CB333B;
$s3d :#52AA4E;
$civiteo: #104D82;
$logiroad:#053c2e;

.K-bg {
  background-color: $keran;
  border: 1px solid $keran;
  &.light {
    background-color: rgba($keran , 0.3) ;
    border: none;
  }
}

.K {
  .primary {
    background-color: $keran;
    border: 1px solid $keran;
    &.light {
      background-color: rgba($keran , 0.3) ;
      border: none;
    }
  }
  .secondary {
    color: $keran;
    background-color: $white;
    border: 1px solid $keran;
  }
  &-color {
    color: $keran;
  }
}

.SCE-bg {
  background-color: $sce;
  border: 1px solid $sce;
  &.light {
    background-color: rgba($sce , 0.3) ;
    border: none;
  }
}

.SCE {
  .primary {
    background-color: $sce;
    border: 1px solid $sce;
    &.light {
      background-color: rgba($sce , 0.3) ;
      border: none;
    }
  }
  .secondary {
    color: $sce;
    background-color: $white;
    border: 1px solid $sce;
  }
  &-color {
    color: $sce;
  }
}

.C-bg {
  background-color: $creo;
  border: 1px solid $creo;
  &.light {
    background-color: rgba($creo , 0.5) ;
    border: none;
  }
}

.C {
  .primary {
    background-color: $creo;
    border: 1px solid $creo;
    &.light {
      background-color: rgba($creo , 0.5) ;
      border: none;
    }
  }
  .secondary {
    color: $creo;
    background-color: $white;
    border: 1px solid $creo;
  }
  &-color {
    color: $creo;
  }
}

.N-bg {
  background-color: $naomis;
  border: 1px solid $naomis;
  &.light {
    background-color: rgba($naomis , 0.5) ;
    border: none;
  }
}

.N {
  .primary {
    background-color: $naomis;
    border: 1px solid $naomis;
    &.light {
      background-color: rgba($naomis , 0.5) ;
      border: none;
    }
  }
  .secondary {
    color: $naomis;
    background-color: $white;
    border: 1px solid $naomis;
  }
  &-color {
    color: $naomis;
  }
}

.GH-bg {
  background-color: $huit;
  border: 1px solid $huit;
  &.light {
    background-color: rgba($huit , 0.5) ;
    border: none;
  }
}

.GH {
  .primary {
    background-color: $huit;
    border: 1px solid $huit;
    &.light {
      background-color: rgba($huit , 0.5) ;
      border: none;
    }
  }
  .secondary {
    color: $huit;
    background-color: $white;
    border: 1px solid $huit;
  }
  &-color {
    color: $huit;
  }
}

.S3D-bg {
  background-color: $s3d;
  border: 1px solid $s3d;
  &.light {
    background-color: rgba($s3d , 0.5) ;
    border: none;
  }
}

.S3D {
  .primary {
    background-color: $s3d;
    border: 1px solid $s3d;
    &.light {
      background-color: rgba($s3d , 0.5) ;
      border: none;
    }
  }
  .secondary {
    color: $s3d;
    background-color: $white;
    border: 1px solid $s3d;
  }
  &-color {
    color: $s3d;
  }
}

.UP-bg {
  background-color: $up;
  border: 1px solid $up;
  &.light {
    background-color: rgba($up , 0.5) ;
    border: none;
  }
}

.YS-bg {
  background-color: $ys;
  border: 1px solid $ys;
  &.light {
    background-color: rgba($ys , 0.5) ;
    border: none;
  }
}

.YS {
  .primary {
    background-color: $ys;
    border: 1px solid $ys;
    &.light {
      background-color: rgba($ys , 0.5) ;
      border: none;
    }
  }
  .secondary {
    color: $ys;
    background-color: $white;
    border: 1px solid $ys;
  }
  &-color {
    color: $ys;
  }
}


.UP {
  .primary {
    background-color: $up;
    border: 1px solid $up;
    &.light {
      background-color: rgba($up , 0.5) ;
      border: none;
    }
  }
  .secondary {
    color: $orange;
    background-color: $white;
    border: 1px solid $orange;
  }
  &-color {
    color: $orange;
  }
}

.main {
  .primary {
    background-color: $orange;
    border: 1px solid $orange;
  }
  .secondary {
    color: $orange;
    background-color: $white;
    border: 1px solid $orange;
  }
}

.CI-bg {
  background-color: $civiteo;
  border: 1px solid $civiteo;
  &.light {
    background-color: rgba($civiteo , 0.3) ;
    border: none;
  }
}

.CI {
  .primary {
    background-color: $civiteo;
    border: 1px solid $civiteo;
    &.light {
      background-color: rgba($civiteo , 0.3) ;
      border: none;
    }
  }
  .secondary {
    color: $civiteo;
    background-color: $white;
    border: 1px solid $civiteo;
  }
  &-color {
    color: $civiteo;
  }
}

.LO-bg {
  background-color: $logiroad;
  border: 1px solid $logiroad;
  &.light {
    background-color: rgba($logiroad , 0.3) ;
    border: none;
  }
}

.LO {
  .primary {
    background-color: $logiroad;
    border: 1px solid $logiroad;
    &.light {
      background-color: rgba($logiroad , 0.3) ;
      border: none;
    }
  }
  .secondary {
    color: $logiroad;
    background-color: $white;
    border: 1px solid $logiroad;
  }
  &-color {
    color: $logiroad;
  }
}