@import './themes.scss';

html , body {
  height: 100%;
  overflow: hidden;
  background-color: $light-grey;
  box-sizing: border-box;
  font-family: "Montserrat-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: inherit;
  @include transition;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  right: 0;
  overflow: auto;
}

#BACKOFFICE-LOGO {
  font-family: "Montserrat-SemiBold";
}

.mobile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
  h2 {
    text-align: center;
  }
}

.page-container {
  width: 100%;
  padding-top: 60px;
  &.site {
    padding-left: 16%;
  }
  &.home-container {
    background-color: $white;
    min-height: 100vh;
  }
}

.page-content {
  @include slideInPage;
  width: calc(100% - 40px);
  max-width: 1300px;
  height: 100%;
  padding: 5px 20px;
  margin: auto;
}

.title-row {
  display: flex;
  width: 100%;
  min-height: 80px;
  align-items: center;
  input {
    max-width: 300px;
    margin: 0px;
  }
  .select {
    width: 300px;
    margin-top: 14px;
  }
  &.space-between {
    justify-content: space-between;
  };
  .title {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    h1, h2 {
      margin: 0px;
      .opacity {
        opacity: 0.2;
      }
    }
    .bar {
      width: 50%;
      max-width: 100px;;
      height: 3px;
    }
    p {
      margin: 5px 0px 0px 0px;
      font-size: 12px;
      .required {
        font-size: 16px;
      }
    }
  }
}

.list-container {
  display: flex;
  flex-direction: column;
  .list-item {
    width: 100%;
    display: flex;
    background-color: $white;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.1);
    .updated-label {
      width: 12px;
      height: 12px;
      min-width: 12px;
      max-height: 12px;
      border-radius: 15px;
      margin-left: -12px;
      transform: translate(-15px, 0px);
      box-shadow: 0px 0px 5px 10px rgba($white,  0.6);
    }
  }
}

.section {
  width: 100%;
  margin-bottom: 60px;
}

.edit-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  .col-content {
    width: 62%;
  }
  .col-aside {
    width: 34%;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $input-shadow;
    padding: 15px;
    h2 {
      margin: 0px 0px 10px 0px ;
    }
  }
}

.error {
  color: red;
}

.text-grey {
  color: $grey;
}


.flex {
  display: flex;
}

h1 {
  font-size: $h1;
  font-family: "Montserrat-SemiBold";
}

.bold {
  font-family: "Montserrat-SemiBold";
}

.d-none {
  display: none;
}

.mb-0 {
  margin-bottom: 0px;
}

.jodit-container {
  border: 1px solid #E0E3EF;
  border-radius: 10px;
  margin: 10px 0px 20px 0px;
  .jodit_wysiwyg {
    font-family: "Montserrat-Regular";
    height: 100%;
    // min-height: 240px;
  }
  .jodit-toolbar__box {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: none;
    border-bottom: 1px solid #E0E3EF;
  }
  &:not(.jodit_inline) {
    border-radius: 12px;
    .jodit_workplace {
      border: none;
    }
  } 
  .jodit_statusbar {
    display: none;
  }
}

input , textarea {
  width: 100%;
  max-width: 100%;
  border: 1px solid #E0E3EF;
  padding: 12px;
  border-radius: 10px;
  font-size: 14px;
  margin: 10px 0px 20px 0px;
  font-family: "Montserrat-Regular";
  background-color: $white;
  transition: all 0.2s ease-in-out;
  &:focus {
    outline: none;
    box-shadow: $input-shadow;
  }
}

.label-file {
  cursor: pointer;
}
.label-file:hover {
  color: #25a5c4;
}

// et on masque le input
.input-file {
  display: none;
}


label {
  font-size: 16px;
  font-family: "Montserrat-SemiBold";
  &.m-10 {
    margin: 10px;
  }
}

button {
  @include btn;
}

.loader {
  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 70px;
}

.btn {
  border: none;
  cursor: pointer;
  font-family: "Montserrat-Regular";
  position: relative;
  top: 0px;
  right: 0px;
  &.primary {
    color: $white;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: $btn-shadow;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05)
    }
  }
  &.success {
    background-color: $success;
    border: 1px solid $success;
    color: $white;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: $btn-shadow;
    &:hover {
      transform: scale(1.05)
    }
  }
  &.pending {
    background-color: $orange;
    border: 1px solid $orange;
    color: $white;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: $btn-shadow;
    &:hover {
      transform: scale(1.05)
    }
  }
  &.danger {
    background-color: $error;
    border: 1px solid $error;
    color: $white;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: $btn-shadow;
    &:hover {
      transform: scale(1.05)
    }
  }
  &.publish {
    background-color: $success;
    border: 1px solid $success;
    position: fixed;
    top: 70px;
    right: 46px;
    box-shadow: $btn-shadow-publish;
    transform: scale(1.2);
    z-index: 1000;
    &:hover {
      transform: scale(1.25)
    }
  }
  &.grey {
    background-color: $light-grey;
    color: $grey;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    &.border {
      border: 1px solid rgba(221, 221, 221, 0.904);
    }
    &:hover {
      background-color: rgba(221, 221, 221, 0.904);
    }
  }
  &.back {
    margin-right: 30px;
  }
  &.secondary {
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: $btn-shadow;
    &:hover {
      transform: scale(1.05)
    }
  }
  &:focus {
    outline: none;
  }
}


h2 {
  font-family: "Montserrat-SemiBold";
  font-size: $h2;
}

.note {
  font-size: 12px;
  font-family: "Montserrat-Regular";
}

.transition {
  @include transition;
}

.modale-container {
  @include transition;
  position: fixed;
  width: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.isActive {
    background-color: rgba($dark , 0.3);
    pointer-events: all;
    z-index: 1201;
  }
  .modale {
    @include transition;
    background-color: $white;
    transform: scale(0);
    box-shadow: $btn-shadow;
    border-radius: $radius;
    &.isActive {
      transform: scale(1);
    }
    .close {
      float: right;
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 25px;
        height: 25px;;
      }
      &:hover {
        background-color: rgba($dark , 0.1)
      }
    }
  } 
}

.delete-modale {
  @include transition;
  min-width: 500px;
  padding: 10px;
  h2 {
    text-align: center;
    margin-left: 32px;
    width: 100%;
  }
  .confirm {
    display: flex;
    justify-content: center;
    button {
      margin: 10px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $light-grey inset;
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #000000;
    --active-inner: #fff;
    --focus: 2px rgba(114, 114, 114, 0.3);
    --border: rgb(218, 218, 218);
    --border-hover: #000000;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: .9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type='checkbox'] {
    &.switch {
      width: 38px;
      padding: 10px;
      margin-bottom: 12px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: .6;
          }
        }
      }
    }
  }
  
}

.jodit_container {
  height: 220px;
}