@import '../../../styles/variables.scss';

.select-companies {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-SemiBold";
    color: $white;
    font-size: 14px;
    width: 50px;
    height: 50px;
    border-radius: $radius;
    margin: 0px 10px 10px 0px ;
    &.notActive {
      background-color: $light-grey;
      color: $grey;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}
.col-aside { 
  input {
    &:focus {
      box-shadow: none;
    }
  }
}

.select-domains {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-SemiBold";
    color: $white;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0px 10px 10px 0px ;
    &.notActive {
      background-color: $light-grey;
      color: $grey;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.select-media {
  .illus-list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    img {
      max-width: calc(50% - 10px);
      margin: 10px 10px 0px 0px;
    }
  }
  button {
    margin: 10px 0px;
  }
  img {
    margin: 10px 0px;

    max-width: 100%;
  }
}