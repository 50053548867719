@import '../../../styles/variables.scss';

.nav-container {
  position: fixed;
  z-index: 1200;
  width: 100%;
  background-color: $white;
  @include transition;
  &.shadow {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: $white;
  }

  &.main {
    background-color: transparent;
  }

  &.site-nav {
    @include slideInPage;
    height: 100%;
    width: 16%;
    z-index: 1100;
  }
  .nav {
    width: calc(100% - 40px);
    padding: 5px 20px;
    margin: auto;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &.site-nav {
      justify-content: center;
      height: 100%;
      padding: 0px;
    }

    .logo-container {
      display: flex;
      align-items: center;
      .logo {
        max-height: 30px;
      }
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
      }
      .logo-company {
        @include animation-mixin-y(slideY, -30px, 0);
        animation: slideY 0.2s ease;
        margin-left: 20px;
        height: 36px;
        width: 36px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: #FFFFFF;
      }
    }

    .nav-links {
      &.site-nav {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      a {
        text-decoration: none;
        font-family: "Montserrat-SemiBold";
        color: $dark;
        padding: 5px 10px;
        margin: 5px 2px;
        font-size: 14px;
        border-radius: 30px;
        &:hover {
          background-color: $light-grey;
        }
      }
    }

    .nav-right {
      height: 45px;
      min-width: 175px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .code {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        opacity: 0.3;
        img {
          object-fit: contain;
          width: 20px;
          height: 20px;
        }
        &:hover {
          opacity: 0.5;
        }
      }

      .language-list {
        margin-right: 20px;
        @include animation-opacity(slideInOpacity);
        animation: slideInOpacity 0.2s ease;
        button {
          height: 30px;
          width: 30px;
          margin: 0 5px;
          border-radius: 20px;
          font-size: $small;
          padding: 0px;
          &.primary {
            color: $white;
          }
          &:hover {
            background-color: $light-grey;
          }
        }
      }

      .user-icon {
        @include btn;
        margin: 0px;
        height: 35px;
        width: 35px;
        padding: 0px;
        border-radius: 20px;
        font-size: $small;
        display: flex;
        align-items: center;
        justify-content: center;;
        background-color: $dark-purple;
        color: $white;
        img {
          transform: scale(0.9) translate(1px , 1px)
        }
      }

    }
  }
}