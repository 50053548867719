
@import '../../../..//styles/variables.scss';

.training {
  @include transition;
  background-color: $white;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 15px;
  box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.1);
  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 80%;
    p {
      margin: 0px;
    }
    .circle {
      width: 40px;
      height: 40px;
      background-color: $light-grey;
      border: 1px solid $grey;
      border-radius: 20px;
      margin: 10px;
      object-fit: cover;
    }
  } 
  &.edit {
    .file {
      a {
        display: block;
        margin-top: 10px;
      }
    }
  }
  &.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      button {
        width: 32px;
        height: 32px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        img {
          width: 20px;
          height: 20px;;
        }
        &:hover {
          background-color: rgba($dark , 0.1);
        }
      }
    }
  }
}