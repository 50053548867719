@import './../../../styles/themes.scss';

.user-row {
  font-size: 14px;
  display: flex;
  .border {
    border-right: 1px solid #E9E9EF;
  }
  .title {
    width: 25%;
    font-size: 16px;
    margin-left: 20px;
  }
  .role {
    width: 12%;
    justify-content: center;
  }
  .companies {
    width: 50%;
    margin-left: 5px;
    flex-wrap: wrap;
    .icon {
      margin: 5px ;
      height: 32px;
      width: 32px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: $white;
    }
  }
  
  .actions {
    width: 10%;
    justify-content: flex-end;
    button {
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 20px;
        height: 20px;;
      }
      &:hover {
        background-color: rgba($dark , 0.1);
      }
    }
  }
}

.user-row > * {
  min-height: 100%;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.add-user-modale {
  width: 60%;
  .user-form {
    margin: 40px;
    .form-row {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .col-2 {
        width: 48%;
        display: flex;
        flex-direction: column;
      }
    }
    .title {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      h2 {
        margin-top: 0px;
      }
      .bar {
        width: 20%;
        height: 2px;;
      }
    }
    input {
      margin-bottom: 5px;
    }
    .error {
      margin: 5px;
      font-size: 14px;
    }
    .btn-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}