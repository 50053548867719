@import '../../../styles/variables.scss';

.home-row {
  display: flex;
  justify-content: flex-end;
}

.col-companies {
  width: 50%;
  left: 20px;
  padding: 2%;
  h1 {
    color: $purple;
    font-family: "Montserrat-SemiBold";
    margin-bottom: 40px;
  }
  .links-container {
    margin: 30px 0px;
    max-width: calc(125px * 4);
    display: flex;
    flex-wrap: wrap;
  }
  a, a:link, a:visited, a:focus, a:hover, a:active{
    color:$dark;
    text-decoration:none;
    text-align: center; 
    cursor: pointer;
    margin: 0px 20px 20px 0px;
    &:hover {
      transform: scale(1.1);
    }
  }  
  .icon {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-SemiBold";
    font-size: 32px;
    text-decoration: none;
    border-radius: $radius;
    box-shadow: $btn-shadow;
    color: $white;
    margin-bottom: 5px;
  }
}

.col-changes {
  width: 50%;
  max-height: 85vh;
  overflow-y: auto;
  padding: 1% 3%;
  right: 20px;
  background-color: #F6F7FB;
  border-radius: $radius;
  scrollbar-color: $dark transparent;
  .updated-label {
    width: 12px;
    height: 12px;
    min-width: 12px;
    max-height: 12px;
    border-radius: 15px;
    margin-top: 4px;
    margin-right: 5px;
    background-color: $success;
    box-shadow: 0px 0px 5px 10px rgba(255, 255, 255, 0.6);
  }
  .notification {
    padding: 15px 0px;
    border-bottom: 1px solid rgba($dark , 0.2);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .message {
      display: flex;
    }
    p {
      margin: 0px;
    }
    a {
      color: $dark
    }
    .logo-list {
      display: flex;
      margin-bottom: 10px;
      .icon {
        color: $white;
        font-size: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
    .time {
      float: right;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background :transparent; 
  border-radius: 15px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($dark , 0.5 );
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark
}
