$light-grey : rgba(243, 243, 243, 0.904);
$orange : #EB690B;
$white: #FFFFFF;
$dark-purple: #271F33;
$purple: #573C7A;
$dark: #000000;
$grey: #9c9c9c;
$dark-grey: #757575;
$success: #49aa56;
$error: #CB333B;

$btn-shadow : 0px 9px 25px 0px rgba(0, 0, 0, 0.27);
$input-shadow : 0px 5px 10px 0px rgba(0, 0, 0, 0.27);
$card-shadow : 0px 2px 10px 0px rgba(0, 0, 0, 0.27);
$btn-shadow-publish : 0px 9px 25px 0px rgba(0, 0, 0, 0.5);

$lg-screen:1200px;
$md-screen: 1025px;
$sm-screen: 768px;
$xs-screen: 576px;

$radius : 10px;
$small : 13px;
$regular : 14px;
$h2 : 18px;
$h1 : 25px;
$margin-md : 30px;
$margin-sm : 15px;
$margin-xs : 10px;

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@mixin btn {
  text-align: center;
	cursor: pointer;
	font-family: "Montserrat-Regular";
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin slideInPage {
	@include animation-mixin-x(slideIn, -30px, 0);
	animation-name: slideIn;
  animation-duration: 0.2s;
}


@mixin animation-mixin-x($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateX($from); opacity: 0;}
    100% {transform: translateX($to); opacity: 1;}
  }
}

@mixin animation-mixin-y($name, $from, $to) {
  @keyframes #{$name} {
    0% {transform: translateY($from); opacity: 0;}
    100% {transform: translateY($to); opacity: 1;}
  }
}

@mixin animation-opacity($name) {
  @keyframes #{$name} {
    0% {opacity: 0;}
    100% { opacity: 1;}
  }
}