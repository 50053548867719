@import '../../../styles/variables.scss';


textarea {
  min-height: 100px;
}

.col-aside {
  label:not(:first-child) {
    display: inline-block;
    margin-top: 10px;
  }
}

.partners-list {
  @include transition;
  margin: 10px 0px ;
  label {
    color: $grey;
    font-size: 14px;
  }
  .edit-partner {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background-color: $light-grey;
    color: $grey;
    input {
      margin: 10px 0px 10px 0px;
    }
    label {
      margin-top: 0px;
    }
  }
  .partner-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
    background-color: $light-grey;
    color: $grey;
    margin-bottom: 10px;
    a {
      color: $grey; 
      &:hover {
        color: $dark-grey; 
      }
    }
    p {
      margin: 0px;
    }
  }
  
}

.select-companies {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-SemiBold";
    color: $white;
    font-size: 14px;
    width: 50px;
    height: 50px;
    border-radius: $radius;
    margin: 0px 10px 10px 0px ;
    &.no-pointer {
      pointer-events: none;
    }
    &.notActive {
      background-color: $light-grey;
      color: $grey;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.sub-domain-list {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  p {
    font-family: "Montserrat-SemiBold";
    width: 100%;
    margin: 5px 0px;
    color: $grey;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-SemiBold";
    color: $white;
    font-size: 11px;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0px 10px 10px 0px ;
    &.notActive {
      background-color: $light-grey;
      color: $grey;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.select-domains {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat-SemiBold";
    color: $white;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0px 10px 10px 0px ;
    &.notActive {
      background-color: $light-grey;
      color: $grey;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.select-dates {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
  input {
    max-width: 120px;
    text-align: center;
    margin: 0px;
  }
  p {
    margin: 10px;
  }
}

.select-media {
  .illus-list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    img {
      max-width: calc(50% - 10px);
      margin: 10px 10px 0px 0px;
    }
  }
  button {
    margin: 10px 0px;
  }
  img {
    margin: 10px 0px;
    max-width: 100%;
  }
  video {
    margin: 10px 0px;
    max-width: 100%;
  }
  .file-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px;
    background-color: $light-grey;
    color: $grey;
    text-decoration: underline;
    margin: 10px 0px;;
    &:hover {
      color: $dark-grey; 
    }
    p , button {
      font-size: 16px;
      text-align: left;
      color: $grey;
      margin: 0px;
    }
    img {
      width: 15px;
      margin: 0px;
    }
  }
}

#map {
  border: 1px solid #E0E3EF;
  margin-top: 10px;
  border-radius: 10px;
  height: 500px;
}

.inputs-map {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .input {
    width: calc(33.33% - 10px )
  }
}