@import './variables.scss';

.edit-row {
  display: flex;
  flex-direction: column;
  .form-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 20px;
    input, textarea {
      margin-bottom: 0px;
    }
    textarea {
      min-height: 80px;
    }
    .error {
      margin: 2px 0px 10px 0px;;
      font-size: 12px;
    }
    .col-1 {
      width: 60%;
      label {
        display: block;
      }
      .btn-close-row {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .btn-delete-bloc {
        font-size: 14px;
        padding: 8px 13px;;
        margin-bottom: 40px;
        border-radius: $radius;
        background-color: $light-grey;
        color: $grey;
        &:hover {
          background-color: $error;
          color: $white;
        }
      }
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h1 {
          font-size: 28px;
          margin: 0px;
        }
        h2 {
          margin:0px;
          font-size: 22px;
        }
        .icon {
          width: 40px;
          margin-right: 10px;
          object-fit: contain;
        }
      }
      .img-large {
        width: 100%;
        margin: 20px 0px ;
      }
      .img-small {
        width: 50%;
        margin: 20px 0px ;
      }
      .select-icon {
        p {
          margin:0px
        }
        button {
          margin: 10px 10px 20px 0px;
        }
      }
    }
    .col-2 {
      width: 35%;
      align-items: flex-start;
      .btn-actions-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button {
          margin-left: 20px;
        }
      }
      .cover {

      }
      img {
        width: 100%;
        max-height: 200px;
        margin: 10px 0px;
        object-fit: cover;
      }
      .illus-list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 10px);
        margin-bottom: 10px;
        img {
          max-width: calc(50% - 10px);
          margin: 10px 10px 0px 0px;
        }
      }
      video {
        margin: 10px 0px;
        max-width: 100%;
      }
      .select-projects {
        margin: 20px 0px;
        display: flex;
        flex-wrap: wrap;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Montserrat-SemiBold";
          color: $white;
          font-size: 12px;
          border-radius: 5px;
          padding: 5px 10px;
          margin: 0px 10px 10px 0px ;
          &.notActive {
            background-color: $light-grey;
            color: $grey;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .file-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        padding: 2px 10px;
        background-color: $light-grey;
        color: $grey;
        text-decoration: underline;
        margin: 10px 0px;;
        &:hover {
          color: $dark-grey; 
        }
        p , button {
          font-size: 16px;
          text-align: left;
          color: $grey;
          margin: 0px;
          max-width: 80%;
        }
        img {
          width: 14px;
        }
      }
      .btn-container {
        display: flex;
        justify-content: center;
        button {
          margin: 5px 10px 20px 0px;
        }
      }
    }
  }
}

// .domain-row {
//   font-size: 14px;
//   min-height: 62px;
//   height: 62px;
//   .border {
//     border-right: 1px solid #E9E9EF;
//   }
//   .title {
//     width: 44%;
//     font-size: 16px;
//     margin-left: 20px;
//     .icon {
//       width: 24px;
//       margin-right: 10px;
//       object-fit: contain;
//     }
//   }
//   .date {
//     width: 44%;
//     justify-content: flex-start;
//   }
  
//   .actions {
//     width: 10%;
//     justify-content: flex-end;
//     button {
//       width: 32px;
//       height: 32px;
//       margin: 5px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       border-radius: 16px;
//       img {
//         width: 20px;
//         height: 20px;;
//       }
//       &:hover {
//         background-color: rgba($dark , 0.1);
//       }
//     }
//   }
// }

// .status > * {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 8px;
//   border-radius: 5px;
//   color: $white;
// }

.domain-row > * {
  height: 100%;
  margin: 0px 0px;
  display: flex;
  align-items: center;
}

.talent , .page, .value {
  @include transition;
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    .circle {
      width: 40px;
      height: 40px;
      background-color: $light-grey;
      border: 1px solid $grey;
      border-radius: 20px;
      margin: 10px;
      object-fit: cover;
    }
  } 
  &.edit {
    background-color: $white;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.1);
  }
  &.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      width: 10%;
      display: flex;
      justify-content: flex-end;
      button {
        width: 32px;
        height: 32px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        img {
          width: 20px;
          height: 20px;;
        }
        &:hover {
          background-color: rgba($dark , 0.1);
        }
      }
    }
  }
}