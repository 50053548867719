@import '../../../styles/variables.scss';

.login-page {
  width: 100%;
  min-height: 100vh;
  background-color: $light-grey;
  &.bg-login {
    background-image: url('../../../assets/images/login-bg.png');
    // background-repeat: no-repeat;
    background-size: 50%;
  }
  .col-content {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
  }
}

.form-container {
  width: 60%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  input {
    width: 100%;
    border: none;
    padding: 12px;
    border-radius: 10px;
    font-size: 14px;
    font-family: "Montserrat-Regular";
    background-color: $light-grey;
    transition: all 0.2s ease-in-out;
    &:focus {
      outline: none;
      box-shadow: $input-shadow;
    }
  }
  
  label {
    font-size: 12px;
    font-family: "Montserrat-SemiBold";
    padding: 5px 0px;
  }

  .logo {
    margin-bottom: 20px ;
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btn-reset {
      padding: 0px;
      font-size: $small;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .error {
    margin: 5px 0px 10px 0px;
    font-size: 12px;
    height: 20px;
    &.error-connexion {
      margin: 20px 0px;
    }
  }
}