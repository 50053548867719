@import '../../../styles/variables.scss';

.article-row {
  font-size: 14px;
  .border {
    border-right: 1px solid #E9E9EF;
  }
  .title {
    width: 25%;
    font-size: 16px;
    margin-left: 20px;
  }
  .date {
    width: 8%;
    justify-content: center;
  }
  .companies {
    width: 20%;
    margin-left: 5px;
    flex-wrap: wrap;
    .icon {
      margin: 5px ;
      height: 32px;
      width: 32px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: $white;
    }
  }
  .domains {
    width: 22%;
    max-width: 24%;
    padding-left: 10px;
    flex-wrap: wrap;
    .domain {
      font-size: 10px;
      border-radius: 5px;
      color: rgba($dark , 0.3);
      margin: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba($dark , 0.2);
      max-height: 20px;
      padding: 5px 10px;
    }
  }
  .status {
    width: 12%;
    justify-content: center;
    text-transform: uppercase;
    font-size: 12px;
    .draft {
      background-color: rgba($dark , 0.2);
      width: 75%;
    }
    .published {
      background-color: $success;
      width: 75%;
    }
  }
  
  .actions {
    width: 10.5%;
    justify-content: flex-end;
    button {
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.2;
      }
      img {
        width: 20px;
        height: 20px;;
      }
      &:hover {
        background-color: rgba($dark , 0.1);
      }
    }
  }
}

.status > * {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  color: $white;
}

.article-row > * {
  min-height: 100%;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.create-article-container {
  width: calc(100%);
  display: flex;
  justify-content: center;
  padding: 20px;
  button {
    width: 130px;
    height: 130px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    &:hover {
      background-color: rgba($dark , 0.1)
    }
    span {
      font-size: 14px;
    }
  }
}

.validate-create-article {
  width: 100%;
  min-height: 42px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.tooltip {
  position: relative;
  display: flex;
  justify-content: flex-start;
  max-height: 38px;
  img {
    width: 15px;
    max-height: 15px;
    margin-right: 8px;
  }
}

/* Tooltip text */
.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
