@import '../../../styles/variables.scss';

.domain-row {
  font-size: 14px;
  min-height: 62px;
  height: 62px;
  .border {
    border-right: 1px solid #E9E9EF;
  }
  .title {
    width: 50%;
    font-size: 16px;
    margin-left: 20px;
    .icon {
      width: 24px;
      margin-right: 10px;
      object-fit: contain;
    }
  }
  .date {
    width: 20%;
    justify-content: flex-start;
  }

  .position {
    width: 20%;
    justify-content: flex-start;
  }
  
  .actions {
    width: 10%;
    justify-content: flex-end;
    button {
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 20px;
        height: 20px;;
      }
      &:hover {
        background-color: rgba($dark , 0.1);
      }
    }
  }
}

.status > * {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  color: $white;
}

.domain-row > * {
  height: 100%;
  margin: 0px 0px;
  display: flex;
  align-items: center;
}

.default-projects {
  font-family: "Montserrat-Regular",
}
