@import '../../../styles/variables.scss';

.agency-row {
  font-size: 14px;
  .border {
    border-right: 1px solid #E9E9EF;
  }
  .title {
    width: 16%;
    font-size: 16px;
    margin-left: 20px;
  }
  .address {
    width: 12%;
    justify-content: center;
  }

  .large {
    width: 18%;
    justify-content: center;
    text-align: center;
    word-break: normal;
    padding: 0px 10px;
  }

  .actions {
    width: 10%;
    justify-content: flex-end;
    button {
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 20px;
        height: 20px;;
      }
      &:hover {
        background-color: rgba($dark , 0.1);
      }
    }
  }
}

.status > * {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  color: $white;
}

.agency-row > * {
  min-height: 100%;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

