@import '../../../styles/variables.scss';

.code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .code {
    width: 600px;
    h1 {
      font-size: 48px
    }
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    img {
      margin: 20px;
    }
  }
}