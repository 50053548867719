@import '../../../styles/variables.scss';

.media-list {
  width: calc(100% -40px);
  display: flex;
  flex-wrap: wrap;
  &.select {
    overflow: auto;
    max-height: 65vh;
    width: calc(100% -40px);
    margin: 20px;
    padding: 20px;
    justify-content: flex-start;
  }
}

.media-card {
  width: calc(16.66% - 20px);
  min-width: 180px;
  background-color: $white;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin: 0px 20px 20px 0px;
  box-shadow: $card-shadow;
  h3 {
    margin: 5px 0px;
    font-size: 16px;
    word-break: break-all;
  }
  p {
    margin: 5px 0px;
    word-break: break-all;
    font-size: 14px;
  }
  .langs {
    display: flex;
    .lang {
      background-color: rgba($dark , 0.1);
      width: 30px;
      height: 30px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin-right: 4px;
      p {
        margin: 0px;
      }
    }
  }
  img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    &.file {
      object-fit: contain;
    }
  }
  video {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
  .remove-container {
    display: flex;
    justify-content: space-between;
    .copy {
      height: 32px;
      color: #969699;
      font-size: 14px;
      border-radius: 5px;
      &:hover {
        background-color: rgba($dark , 0.1);
      }
    }
    .remove {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
      &:hover {
        background-color: rgba($dark , 0.1);
      }
    }
  }
}

.add-media-modale {
  width: 40%;
  max-height: 90vh;
  overflow: auto;
  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    img , video {
      max-height: 200px;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .error {
    width: 100%;
    font-size: 12px;
    text-align: right;
    margin: 0px;
  }
  input, textarea {
    margin: 10px 0px;
  }
  .btn-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .preview {
      max-width: 300px;
      max-height: 120px;
      margin-bottom: 20px;
    }
  }
  .thumbnail-list {
    width: 100%;
    margin: 20px 0px;
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    .thumbnail {
      margin:  10px 8px;
      min-width: 120px;
      max-height: 100px;
      object-fit: cover;
      img {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        box-shadow: $input-shadow;
        border: 2px solid $white;
        &.active {
          border: 2px solid $success;
          transform: scale(1.1)
        }
      }
    }
  }
  form {
    margin: 30px;
    textarea {
      min-width: 100%;
    }
  };
}

.select-media-modale {
  max-width: 90%;
  input {
    width: 50%;
    margin:20px;
  }
  .select-card {
    max-width: 200px;
    cursor: pointer;
    .selected {
      border: 1px solid $success;
      background-color: rgba($success , 0.1)
    }
  }
  .btn-container {
    width:100%;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }
}