@import '../../../styles/variables.scss';

.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.edit-row {
  display: flex;
  flex-direction: column;
  .form-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 20px;
    input, textarea {
      margin-bottom: 0px;
      &:focus {
        box-shadow: none;
      }
    }
    .error {
      margin: 2px 0px 10px 0px;;
      font-size: 12px;
    }
    .col-1 {
      width: 60%;
      .title {
        display: flex;
        align-items: center;
        h1 {
          margin: 0px;
        }
        .icon {
          width: 40px;
          margin-right: 10px;
          object-fit: contain;
        }
      }
      .select-icon {
        button {
          margin: 10px 10px 20px 0px;
        }
      }
    }
    .col-2 {
      width: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn-actions-container {
        width: 100%;
        display: flex;
        margin-bottom: 30px;
        justify-content: flex-end;
        button {
          margin-left: 20px;
        }
      }
      img {
        width: 100%;
        max-height: 200px;
        margin: 10px 0px;
        object-fit: cover;
      }
      .btn-container {
        display: flex;
        justify-content: center;
        button {
          margin: 10px;
        }
      }
    }
  }
}

.contact-row {
  font-size: 14px;
  .border {
    border-right: 1px solid #E9E9EF;
  }
  .name {
    width: 43%;
    font-size: 16px;
    margin-left: 20px;
  }

  .large {
    width: 43%;
    justify-content: flex-start;
  }

  .actions {
    width: 10%;
    justify-content: flex-end;
    button {
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 20px;
        height: 20px;;
      }
      &:hover {
        background-color: rgba($dark , 0.1);
      }
    }
  }
}

.status > * {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  color: $white;
}

.contact-row > * {
  min-height: 100%;
  margin: 10px 0px;
  display: flex;
  align-items: center;
}

.select-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    margin: 5px;
  }
  .btn-container {
    display: flex;
    .btn {
      width: 130px;
      margin-bottom: 10px;
    }
  }
}

.check {
  display: flex;
  align-items: center;
  input[type='checkbox'].switch {
      width: 38px;
      padding: 10px;
      margin-bottom: 0px;
      border-radius: 11px;
  }
  p {
    margin: 0px;
  }
}
